import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';

import appointments from './demo-data/today-appointments';

export default function Calendar(props){
    return (
        <div className="calendar"
        style={{
            width: '970px',
            position: 'inherit',
            margin: 'auto'
          }}
        >
            <Paper>
                <Scheduler data={appointments} height={460}>
                    <WeekView startDayHour={10} endDayHour={24} />
                    <Appointments/>
                    <AppointmentTooltip />
                </Scheduler>
            </Paper>
        </div>
        
    );
};