import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Calendar from "components/Calendar/Calendar.js";
import Page from "../Page/Page.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
        <Page children={<Calendar/>}/>
    </div>
  );
}
