import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import Page from "views/Page/Page.js"
import CalendarPage from "views/CalendarPage/CalendarPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/calendar" component={CalendarPage} />
      <Route path="/" component={Page} />
    </Switch>
  </Router>,
  
  document.getElementById("root")
);
